const fn1 = () => {
  // 加載facebook SDK
  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0]

    if (d.getElementById(id)) {
      return
    }
    const js = d.createElement(s)

    js.id = id
    js.src = 'https://connect.facebook.net/en_US/sdk.js'
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')
  // 初始化facebook
  window.fbAsyncInit = function () {
    FB.init({
      appId: '1713053685635172',
      cookie: true, // Enable cookies to allow the server to access the session.
      xfbml: true, // Parse social plugins on this webpage.
      version: 'v8.0' // Use this Graph API version for this call.
    })
  }
}

export default fn1
