import axios from 'axios'
// http request 请求拦截器
axios.interceptors.request.use(
  config => {
    if (config.method === 'post' && config.headers['Content-Type'] == 'application/x-www-form-urlencoded') {
      // let data = ''
      // for (let item in config.data) {
      //   if (config.data[item]) { data += encodeURIComponent(item) + '=' + encodeURIComponent(config.data[item]) + '&' }
      // }
      // config.data = data.slice(0, data.length - 1)
    } else {
      config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http response 服务器响应拦截器
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)
export default axios
