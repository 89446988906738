<template>
  <el-dialog
    id="tvu-google-tip"
    :visible.sync="googleVisible"
    :title="$t('tvuCommonGoogleLoginTip.title')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    top="0"
    width="40%"
  >
    <div
      slot="title"
      class="header-title">
      <i
        class='iconfont-google icon-Warning'
        style='font-size: 32px; color: red'></i>
      <span class="title">{{$t('tvuCommonGoogleLoginTip.title')}}</span>
    </div>
    <div class="clearfix">
      <div class="fl">
        <div class="info">{{$t('tvuCommonGoogleLoginTip.name')}}</div>
        <ul>
          <li>
            {{$t('tvuCommonGoogleLoginTip.tips1')}}
          </li>
          <li>
            {{$t('tvuCommonGoogleLoginTip.tips2')}}
          </li>
          <li>
            {{$t('tvuCommonGoogleLoginTip.tips3')}}
          </li>
        </ul>
      </div>
    </div>
    <div slot="footer">
      <span
        class="dowm"
        v-if="langType">{{$t('tvuCommonGoogleLoginTip.shutdown')}}<span class="number">{{number}}</span>s</span>
      <span
        class="dowm"
        v-else><span class="number">{{number}}</span>{{$t('tvuCommonGoogleLoginTip.shutdown')}}</span>
      <el-button
        class="fr confirmBtn"
        @click="confirm"
      >{{$t('tvuCommonGoogleLoginTip.got')}}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import Bus from '@/assets/js/vueBus'
export default {
  name: 'GoogleTip',
  data () {
    return {
      googleVisible: false,
      number: 10,
      timerGoogle: null,
      langType: true
    }
  },
  created () {
    Bus.$on('googleChange', (val) => {
      this.googleVisible = val
      this.shutdown()
      this.langType = this.$i18n.locale != 'zh'
    })
  },
  computed: {},
  methods: {
    shutdown () {
      this.number = 10
      this.timerGoogle = setInterval(() => {
        this.number--
        if (this.number == 0) {
          clearInterval(this.timerGoogle)
          this.confirm()
        }
      }, 1000)
    },
    confirm () {
      this.googleVisible = false
      clearInterval(this.timerGoogle)
    }
  }
}
</script>
<style lang="less" scoped>
#tvu-google-tip {
  background-color: transparent;
}
::v-deep .el-dialog {
  border-radius: 12px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: left;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

::v-deep .el-dialog__title {
  font-size: 20px;
}

::v-deep .el-dialog__header {
  padding-left: 32px;
  font-weight: bold;
  padding-top: 34px;
  padding-bottom: 8px;
}

::v-deep .el-dialog__body {
  padding: 0 0 36px 88px;
  color: #28282E;
  font-size: 16px;
  line-height: 20px;
}

::v-deep .el-dialog__footer {
  padding-bottom: 32px;
}
.header-title {
  display: flex;
  align-items: center;
  .title {
    margin-left: 24px;
  }
}

#tvu-google-tip{
  ul {
    list-style: initial;
    color: #505050;
    margin: 0;
    padding-left: 17px;
    font-size: 14px;
    li {
      line-height: 22px;
    }
  }

  .info{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
  }
}

.dowm {
  color: #979DA3;
  margin-right: 32px;
  line-height: 40px;
  font-size: 14px;
}

.number {
  color: #333;
  margin: 0 5px;
}

.confirmBtn {
  width: 96px;
  height: 40px;
  font-size: 14px;
  background: #F6445A;
  color: #fff;
  border: none;
}

.el-button:focus, .el-button:hover {
  background: #F6445A;
  color: #fff;
}
</style>
