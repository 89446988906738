import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './assets/js/axiosExtend.js'
import ElementUI from 'element-ui'
import VueTelInput from 'vue-tel-input'
import i18n from './assets/lang/index'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/index.less'
import './assets/css/theme.less'
import '@/assets/iconfont/iconfont.css'
import '@/assets/googleIconfont/iconfont.css'
import '@/assets/iconfont/iconfont.js'
import preventClick from './assets/js/preventClick.js'
import 'babel-polyfill'
import { GoogleLoginTip, LoginUserInfo, TopHeader } from 'tvu-common-ui'

Vue.use(preventClick)
Vue.use(ElementUI)
Vue.use(GoogleLoginTip)
Vue.use(LoginUserInfo)
Vue.use(TopHeader)
Vue.prototype.$TVU = {
  isUserService: false,
  http: axios,
  domain: window.location.protocol + '//' + window.location.host
}
Vue.use(VueTelInput, {
  enabledCountryCode: true,
  inputClasses: 'telephone',
  placeholder: 'Phone number',
  validCharactersOnly: true,
  defaultCountry: '',
  mode: 'national',
  customValidate: false
})
Vue.config.productionTip = false
Vue.prototype.$qs = require('qs')
Vue.prototype.$axios = axios
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
