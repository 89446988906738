// 设置语言
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zh from './zh.js'
import en from './en.js'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n)
const DEFAULT_LANG = navigator.language || navigator.userLanguage
const lang = DEFAULT_LANG.split('-')[0]
window.localStorage.setItem('lang', lang)
// 实例化
const i18n = new VueI18n({
  locale: DEFAULT_LANG, // 默认语言
  messages: {
    // 引用语言包
    zh: {
      ...zh,
      ...zhLocale
    },
    en: {
      ...en,
      ...enLocale
    }
  },
  silentTranslationWarn: true
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
window.i18n = i18n
export default i18n
