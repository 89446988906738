<template>
  <div
    id="app"
    v-loading="loading">
    <router-view
      v-if="showPopup && !loading"
      :class="{'pop-sign-wrap': showPopup}"
      class="sign-wrap"/>
    <div
      style="height: 100%;"
      v-if="!showPopup && !loading">
      <div
        v-if="$route.path != '/configPage' && $route.path != '/waitingPage' && $route.path != '/welcome' && isPc"
        class="banner-img"
        :style="{
          'z-index': backgroungList.length,
          background: `url(${backgroungList[0]})`,
        }">
        <div
          class="content-wrap"
          v-if="!isShowMyID">
          <div class="server-name-box">
            <div class="server-name">
              <img
                class="tvu-img"
                src="https://dl.tvunetworks.com/static/html/TVU_Logo.png"
                alt=""/>
              <span class="name">{{ serverName }}
                <span
                  class="copyRight"
                  v-if="serverName == 'TVU networks'">&reg;</span>
              </span>
            </div>
            <div
              v-if="isShowAppStore"
              class="ac">
              <a
                :href="appStoreUrl"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src="@/assets/images/app store.svg"
                  alt=""/>
              </a>
            </div>
          </div>
          <div class="flex-column-end">
            <router-view
              class="sign-wrap"
              :class="isPc ? 'pc-sign-wrap' : ''"/>
            <div
              class="lang-footer">
              <span class="title">{{ $t("changeLang") }}：</span>
              <el-dropdown
                class="langSelector cursor"
                trigger="click"
                @command="changeLang">
                <span class="el-dropdown-link">
                  <img
                    :src="lang.src"
                    alt=""
                    style="margin-right: 5px"/>
                  <span class="langText">{{ lang.label }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu
                  class="lang"
                  slot="dropdown">
                  <el-dropdown-item
                    v-for="item in options"
                    :key="item.value"
                    :command="item"><img
                                      :src="item.src"
                                      alt=""
                                      style="margin-right: 5px"/>
                    <span>{{ item.label }}
                    </span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <!-- 迪士尼 -->
        <div
          class="disneyContent"
          v-else>
          <div class="disneyIcon">
            <img
              class="disneyImg"
              src="https://dl.tvunetworks.com/static/html/TVU_Logo.png"
              alt=""/>
            <span class="serviceName">{{ serverName }}
              <span
                class="copyRight"
                v-if="serverName == 'TVU networks'">&reg;</span>
            </span>
          </div>
          <div class="cursor">
            <a
              :href="myIdUrl"
              class="loginBtn">Sign in with
              <span>Disney MyID</span>
            </a>
          </div>
        </div>
      </div>
      <div
        v-else
        class="content-box">
        <div
          class="mobile-server-name"
          v-if="$route.path != '/configPage' && $route.path != '/waitingPage' && $route.path != '/welcome'">
          <img
            class="tvu-img"
            src="https://dl.tvunetworks.com/static/html/TVU_Logo.png"
            alt=""/>
          <span class="name">{{ serverName }}
            <span
              class="copyRight"
              v-if="serverName == 'TVU networks'">&reg;</span>
          </span>
        </div>
        <router-view :class="isPc ? '' : ['sign-wrap', 'mobile-sign-wrap'] "/>
      </div>
    </div>
    <!-- 谷歌登录提示 -->
    <google-login-tip v-if='showGoogleTip'></google-login-tip>
    <GoogleTip></GoogleTip>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus'
import fn1 from '@/assets/js/facebook.js'
import { getUrlParam } from '@/assets/js/utils'
// import googleLogin from '@/assets/js/googleLogin.js'
import GoogleTip from './components/GoogleTip'

export default {
  name: 'App',
  data () {
    return {
      backgroungList: [
        // require('@/assets/images/bg1.jpg'),
        require('@/assets/images/bg2.jpg'),
        require('@/assets/images/bg3.jpg'),
        require('@/assets/images/bg4.jpg'),
        require('@/assets/images/bg5.jpg'),
        require('@/assets/images/bg6.jpg'),
        require('@/assets/images/bg7.jpg'),
        require('@/assets/images/login1.jpg'),
        require('@/assets/images/login2.jpg'),
        require('@/assets/images/login3.jpg'),
        require('@/assets/images/login4.jpg'),
        require('@/assets/images/login5.jpg'),
        require('@/assets/images/login6.jpg')
      ],
      serverName: '',
      options: [
        {
          value: 'zh',
          label: '中文',
          src: require('@/assets/images/🇨🇳.png')
        },
        {
          value: 'en',
          label: 'English',
          src: require('@/assets/images/🇺🇸.png')
        }
      ],
      lang: {},
      currentLang: 'en',
      isPc: false,
      showGoogleTip: false,
      isShowAppStore: false,
      appStoreUrl: '',
      googleVisible: false,
      domainName: '',
      tagKeyList: [],
      isCheckSession: false,
      sessionAppend: false,
      myIdUrl: '',
      showPopup: false,
      guestEmail: '',
      loading: false
    }
  },
  components: {
    GoogleTip
  },
  computed: {
    isShowMyID () {
      return this.$store.state.isShowMyID
    }
  },
  created () {
    const localUrl = window.location.hostname
    const str1 = localUrl.split('.')[1]
    const str2 = localUrl.split('.')[2]
    this.domainName = `.${str1}.${str2}`
    this.setLang()
    this.getServiceName()
    this.getInitData()
    this.getLocalUrl()
    this.isPCMethod()
    Bus.$on('jumpLoginPage', function () {
      // window.location.href = that.initData.initConfig.userGroupUrl;
      const hostPath =
          window.location.protocol +
          '//' +
          window.location.host +
          '/newloginservice'

      window.location.href = hostPath
    })
    Bus.$on('showGoogleTipEvent', () => {
      this.showGoogleTip = window.location.hash === '#/signIn'
    })
    if (location.host.includes('com')) {
      this.appStoreUrl =
          'https://apps.apple.com/us/app/tvu-cc/id1501478726'
    } else {
      this.appStoreUrl =
          'https://apps.apple.com/cn/app/tvu-cc/id1501478726'
    }
    // if (window.location.href.indexOf('/welcome') > -1) {
    //   this.$store.state.isClearSid = true
    // } else {
    //   this.$store.state.isClearSid = false
    // }
    this.backgroungList = this.backgroungList.map((n, i, all) => {
      const j = i + Math.floor(Math.random() * (all.length - i))
      const v = all[j]

      all[j] = n
      return v
    })
  },
  methods: {
    googleSessionFlag () {
      let googleSessionFlag = ''
      let cookieArr = document.cookie.split(';')
      for (let v of cookieArr) {
        if (v.split('=')[0].toLowerCase().trim() === 'googlesessionflag') {
          googleSessionFlag = v.split('=')[1]
          break
        }
      }
      return googleSessionFlag === '1'
    },
    async getInfoJson () {
      let cookieArr = document.cookie.split(';')
      let cookieCode = ''
      let serviceName = getUrlParam('serviceName')
      for (let v of cookieArr) {
        if (v.split('=')[0].toLowerCase().trim() === 'sid') {
          cookieCode = v.split('=')[1]
          break
        }
      }
      // 先排断有没有谷歌登录显示，如果有直接跳到登录页面，再判断有没有sid, 没有sid，则跳转到signIn页面
      // 账号被锁定解锁成功页面, 解锁账号重置密码页面，也要清除sid
      // 删除账号页面也要清除sid
      if (this.googleSessionFlag() || window.location.href.indexOf('/unlockedSuccess') > -1 || window.location.href.indexOf('/changePsw') > -1 || window.location.href.indexOf('/configPage') > -1 || window.location.href.indexOf('/deleteAccount') > -1) {
        this.$store.state.isClearSid = false
        this.$store.commit('changesValidSid', false)
        const exp = new Date()
        exp.setTime(exp.getTime() - 1)
        window.document.cookie = `SID=;path=/;domain=${this.domainName};expires=${exp.toGMTString()};${this.$store.state.httpsFlag ? 'Secure=true;' : ''}`
        this.tagKeyList.forEach((item) => {
          window.document.cookie = `${item}=;path=/;domain=${this.domainName};expires=${exp.toGMTString()}`
        })
        // Bus.$emit('canClearSid')
        return
      }
      await this.$axios.get(localStorage.getItem('userserviceAuthUrl') + '/main/checkUserAuth', {
        params: {
          serviceName: serviceName
        }
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          // 有效的sid
          this.$store.commit('changesValidSid', true)
          this.$store.state.isClearSid = true
          Bus.$emit('sendUserInfoMessage', res.data.result)
          // 进入登录页面时先判断是否有cookie，如果有直接登录
          // 如果是游客身份不需要跳转到服务,保留sid,清除tag
          if (this.guestEmail === res.data.result.email) {
            this.clearTagKey()
          } else {
            this.checkHasCookie(cookieCode)
          }
        } else {
          this.clearCookie()
        }
      }).catch(() => {
        this.clearCookie()
      })
    },
    clearTagKey () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      this.tagKeyList.forEach((item) => {
        window.document.cookie = `${item}=;path=/;domain=${this.domainName};expires=${exp.toGMTString()}`
      })
    },
    clearCookie () {
      const exp = new Date()
      exp.setTime(exp.getTime() - 1)
      window.document.cookie = `SID=;path=/;domain=${this.domainName};expires=${exp.toGMTString()};`
      // 失效的sid
      this.$store.state.isClearSid = false
      Bus.$emit('canClearSid')
      this.$store.commit('changesValidSid', false)
    },
    routerPush (path) {
      const href = window.location.href
      const flag = href.includes('type=')
      if (flag) {
        // 移动端需要携带本来url上的参数
        const url = path + '?' + href.split('?')[1]
        this.$router.push({ path: url })
      } else {
        this.$router.push(path)
      }
    },
    checkHasCookie (cookieCode) {
      localStorage.setItem('session', cookieCode)
      let directUrl = ''

      if (window.location.href.indexOf('/welcome') > -1) {
        this.$store.state.isClearSid = true
        return
      }
      if (!getUrlParam('url') || getUrlParam('url').includes('null')) {
        this.$store.state.isClearSid = true
        // this.$router.push('/welcome')
        this.routerPush('/welcome')
      } else {
        this.isCheckSession = localStorage.getItem('sessionAppendService').includes(getUrlParam('serviceName'))
        // 在sessionAppendService列表里的服务，url需要拼接上session，不在的不拼session
        if (this.sessionAppend) {
          directUrl = `${getUrlParam('url')}?session=${cookieCode}`
        } else {
          if (this.isCheckSession) {
            directUrl = `${getUrlParam('url')}?session=${cookieCode}`
          } else {
            directUrl = `${getUrlParam('url')}`
          }
        }
        window.top.location.href = directUrl
      }
    },
    getCookie (name) {
      const arr = document.cookie.match(
        new RegExp('(^| )' + name + '=([^;]*)(;|$)')
      )

      if (arr != null) {
        return unescape(arr[2])
      }
      return false
    },
    delCookie (name) {
      const exp = new Date()

      exp.setTime(exp.getTime() + -1 * 24 * 60 * 60 * 1000)
      document.cookie =
          name +
          '=' +
          this.getCookie(name) +
          ';path=/;domain=' +
          this.domainName +
          ';expires=' +
          exp.toGMTString() // "isChecked" + "=" + this.checked + ";path=/;domain="+this.domainName+";expires=" + exdate.toGMTString();
    },
    getLocalUrl () {
      let val

      // web 传参https://newuserservice.tvunetworks.com/newuserservice/?url=https://cc.tvunetworks.com/tvucc&serviceName=Command%20Center#/signIn
      let url

      let envFlag = true
      const href = window.location.href

      if (href.indexOf('?') > -1) {
        if (process.env.NODE_ENV === 'production' && !href.includes('newloginservice')) {
          envFlag = false
        }
        if (envFlag) {
          const encodeHref = decodeURIComponent(href)

          val = encodeHref.substring(encodeHref.indexOf('?') + 1)
          if (val.includes('serviceName')) {
            val = val.split('url=')[1].split('#')[0].split('&serviceName=')
            url = val[0]
          }
        }
        this.$store.state.location = url
      } else {
        this.$store.state.location = null
      }
    },
    setLang () {
      // 设置默认语言
      this.currentLang =
          localStorage.getItem('lang') ||
          navigator.language ||
          navigator.userLanguage
      if (this.currentLang.indexOf('zh') > -1) {
        this.$i18n.locale = 'zh'
        this.lang = {
          value: 'zh',
          label: '中文',
          src: require('@/assets/images/🇨🇳.png')
        }
      } else {
        this.$i18n.locale = 'en'
        this.lang = {
          value: 'en',
          label: 'English',
          src: require('@/assets/images/🇺🇸.png')
        }
      }
    },
    changeLang (lang) {
      ga('send', 'event', 'newUserService-home-changeLang', 'click')
      this.lang = lang
      this.currentLang = lang.value
      this.$i18n.locale = lang.value
      window.localStorage.setItem('lang', lang.value)
    },
    getServiceName () {
      const href = window.location.href

      if (href.indexOf('?') == -1) {
        this.serverName = 'TVU networks'
        return
      }
      let val = []

      if (href.includes('type=')) {
        // 移动端传参'http://211.160.178.10:20088/newuserservice/#/signIn?type=tvuhitme&peerId=1234&deviceName=aaa&vertion=111&deviceType=r';
        val = decodeURIComponent(href).split('?')
        let serverName = ''
        if (val[1].includes('serverName')) {
          serverName = val[1].split('serverName=')[1].split('&')[0]
          this.serverName = serverName
          return
        }
        if (val[1].includes('type')) {
          serverName = val[1].split('type=')[1].split('&')[0]
          if (serverName) {
            switch (serverName) {
              case 'tvuhitme':
                this.serverName = 'TVU HitMe'
                break
              case 'tvuanywhere':
                this.serverName = 'TVU Anywhere'
                break
              case 'plustvuanywhere':
                this.serverName = 'TVU Anywhere Plus'
                break
              case 'tvuccmobile':
                this.serverName = 'TVU CCMobile'
                break
              default:
                this.serverName = serverName
                break
            }
          }
        }
      } else {
        // web 传参https://newuserservice.tvunetworks.com/newuserservice/?url=https://cc.tvunetworks.com/tvuccl?taskid=3e3f0612-f79c-41f9-80a7-13601ccec8c4&serviceName=Command%20Center#/signIn
        val = decodeURIComponent(href).split('?')
        let params = ''

        if (val.length > 2) {
          params = val[1] + '?' + val[2]
        } else {
          params = val[1]
        }
        if (params.includes('serviceName')) {
          this.serverName = getUrlParam('serviceName')
        } else {
          this.serverName = 'TVU networks'
        }
      }
    },
    // 加载js
    loadScript (src) {
      let script = document.createElement('script')
      script.src = src
      script.async = true
      document.head.appendChild(script)
    },
    getInitData () {
      this.loading = true
      this.$axios.get(
        '/login-service/login/initConfig').then((res) => {
        if (res.data.errorCode === '0x0') {
          this.loading = false
          const urlObj = res.data.result.myIdUrl
          const host = window.location.hostname
          let result = res.data.result
          // 在弹窗内嵌入的服务
          this.showPopup = result.popupServiceList.includes(getUrlParam('popupService'))
          this.$store.state.showPopup = this.showPopup
          this.$store.state.clearCookiePathList = result.clearCookiePathList
          // 中国区、App端、media hub弹窗内注册不加载，全球区加载
          if (!location.host.includes('cn') && this.isPc && !this.showPopup) {
            this.loadScript(`${process.env.BASE_URL}19963008.js`)
          }
          // guestEmail
          this.guestEmail = result.guestEmail
          this.$store.state.popupServiceList = result.popupServiceList
          // 公共服务
          this.$store.state.publicServers = result.publicServers
          // 隐藏注册按钮的服务
          this.$store.state.hideRegisterServices = result.hideRegisterServices
          // 隐藏隐私政策的服务
          this.$store.state.hidePrivacyTypes = result.hidePrivacyTypes
          if (result.gtag && result.gtagServiceName && result.gtagServiceName.includes(this.serverName)) {
            this.initGtag(result.gtag)
          }
          localStorage.setItem('googleCode', result.googleCode)
          // 微软登录clientId
          localStorage.setItem('microsoftClientId', result.microsoftClientId)
          // 九宫格列表服务地址
          localStorage.setItem('userserviceAuthUrl', result.userAuthUrl)
          localStorage.setItem('userserviceUrl', result.userservice)
          this.$store.state.httpsFlag = result.httpsFlag
          // check url
          if (window.location.protocol == 'http:' && result.httpsFlag) {
            const url = window.location.href.replace(/(http):/, 'https:')

            window.location.href = url
            this.$store.states.href = url
            sessionStorage.setItem('store', JSON.stringify(this.$store.state))
          }
          // check一下是否有cookie，且cookie是否有效，若有效则直接登录，无效才显示页面
          this.tagKeyList = result.tagKeys || []
          localStorage.setItem('sessionAppendService', result.sessionAppendService)
          this.sessionAppend = result.sessionAppend
          this.getInfoJson()
          // 第三方登陆数组
          const tempList = []
          result.socialLogin.forEach((item) => {
            item ? tempList.push(item) : ''
          })
          this.$store.commit('changeSocialLogin', tempList)
          // websocket地址
          this.$store.commit('setWebsocketUrl', result.ccWebsocket)
          result.socialLogin.indexOf('FaceBook') > -1 ? fn1() : ''
          if (result.appleServer != undefined) {
            result.appleServer.includes(this.serverName) ? (this.isShowAppStore = true) : (this.isShowAppStore = false)
          }
          for (const i in urlObj) {
            if (host == i) {
              this.$store.commit('changeIsShowMyID', true)
              this.$store.commit('changeMyIdUrl', urlObj[i])
              this.myIdUrl = urlObj[i]
              return
            }
          }
          this.$store.commit('changeTagKeyList', result.tagKeys || [])
          this.$store.commit('changeSearchRegisterUrl', result.searchRegisterUrl)
          this.$store.commit('setServiceListUrl', result.serviceListUrl)
          this.$store.commit('setWechatAppId', result.wechatAppId)
          this.$store.commit('saveTagCookie', result.saveTagCookie || false)
          this.$store.state.googleClientId = result.googleClientId
          this.$store.state.googleRedirectUrl = result.googleRedirectUrl
          // if (result.socialLogin.includes('Google')) googleLogin(result.googleClientId)
        } else {
          this.$message({
            message: res.data.errorInfo,
            type: 'error',
            duration: 3000
          })
        }
      }).catch(() => {
        this.loading = false
      })
    },
    initGtag (tag) {
      // Global site tag (gtag.js) - Google Analytics
      (function (h, o, t, j, a, r) {
        a = o.getElementsByTagName('head')[0]
        r = o.createElement('script')
        r.async = 1
        r.src = t + j
        a.appendChild(r)
      })(window, document, 'https://www.googletagmanager.com/gtag/js?id=', tag)
      window.dataLayer = window.dataLayer || []

      function gtag () {
        dataLayer.push(arguments)
      }

      gtag('js', new Date())
      gtag('config', tag)
    },
    isPCMethod () {
      // 判断平台
      const userAgentInfo = navigator.userAgent
      const Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
      ]

      let flag = true

      for (let v = 0; v < Agents.length; v++) {
        if (
          userAgentInfo.indexOf(Agents[v]) > 0 ||
            (/Mac/.test(navigator.userAgent) &&
                navigator.maxTouchPoints > 0)
        ) {
          flag = false
          break
        }
      }
      // const anywheres = ['tvuhitme', 'tvuanywhere', 'tvuccmobile']
      const anywheres = ['tvuhitme', 'plustvuanywhere', 'tvuanywhere', 'tvuccmobile', 'tvusidecar', 'tvureplay', 'tvureview', 'partylineapp']

      if (anywheres.indexOf(getUrlParam('type')) > -1) {
        flag = false
      }
      this.isPc = flag
      this.$store.commit('getIsPc', this.isPc)
    }
  }
}
</script>
<style lang="less" scoped>
.banner-img {
  width: 100%;
  min-height: 100%;
  background-size: cover !important;
  padding: 50px 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .server-name-box {
    margin-right: 300px;
    min-width: 300px;
  }

  .server-name {
    color: #FFFFFF;
    line-height: 57px;
    font-size: 32px;
    font-weight: 700;
    display: flex;
    align-items: center;

    .tvu-img {
      width: 42px;
      margin-right: 10px;
    }

    .copyRight {
      transform: translateY(-10px);
      position: absolute;
      font-size: 15px;
    }
  }
}

.lang-footer {
  text-align: right;
  margin-top: 50px;

  .title {
    color: #bcbcbc;
    font-size: 16px;
  }
}

.mobile-server-name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .tvu-img {
    width: 36px;
  }

  .name {
    font-size: 20px;
    font-weight: 700;
    margin-left: 8px;
    color: #999;
  }
}

.content-box {
  height: 100%;
}

.disneyContent {
  z-index: 10;
  text-align: center;

  .disneyIcon {
    .disneyImg {
      height: 55px;
    }

    .serviceName {
      margin-left: 20px;
      line-height: 64px;
      font-size: 50px;
      font-weight: bold;
      vertical-align: top;
      color: rgba(255, 255, 255, 1);

      .copyRight {
        transform: translateY(-10px);
        position: absolute;
        font-size: 15px;
      }
    }
  }

  .loginBtn {
    margin-top: 128px;
    display: inline-block;
    width: fit-content;
    height: 40px;
    background: linear-gradient(241deg,
    rgba(193, 222, 0, 1) 0%,
    rgba(95, 168, 8, 1) 100%) rgba(255, 255, 255, 0.6);
    border-radius: 25px;
    line-height: 40px;
    padding: 0 25px;
    font-size: 16px;
    color: rgba(27, 27, 27, 1);

    span {
      font-weight: bold;
    }
  }
}
</style>
