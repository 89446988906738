export const getUrlParam = function (name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const r =
    window.location.search.substr(1).match(reg) ||
    window.location.hash
      .substr(window.location.hash.indexOf('?') + 1)
      .match(reg)

  if (r != null) {
    return unescape(r[2])
  }
  return null
}
export const setCookie = (value, key = cookieName, expiredays = 30, domain = domainName) => {
  value = encodeURIComponent(value)
  let exdate = new Date()
  exdate.setDate(exdate.getDate() + expiredays)
  exdate = exdate.toGMTString()
  document.cookie = `${key}=${value};expires=${exdate};path=/;domain=${domain}`
}

// 获取cookie
export const getCookie = (key = cookieName) => {
  let reg = new RegExp(`(^| )${key}=([^;]*)(;|$)`)
  let arr = document.cookie.match(reg)
  return arr ? decodeURIComponent(arr[2]) : null
}

// 清除cookie
export const removeCookie = (key = cookieName, domain = domainName) => {
  setCookie(null, key, -30, domain)
}
