import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    userInfo: {
      account: '',
      msgType: '',
      country: ''
    },
    verifyCode: '', // 图片验证码
    email: '', // 用户邮箱
    key: '',
    location: '',
    emailCode: '',
    href: '', // 登录链接地址
    isSuccess: false,
    isShowMyID: false,
    isPc: true,
    myIdUrl: '',
    tagKeyList: '',
    httpsFlag: false,
    isShowConfig: false,
    socialLogin: [],
    contactEmail: '',
    searchRegisterUrl: '',
    saveTagCookie: false,
    websocketUrl: '', // websocket地址
    serviceListUrl: '', // 用来获取新九宫格图片资源
    validSid: false, // 是否是有效的sid， 没有sid以及有sid但是sid失效，都是为无效的sid
    publicServers: [],
    hideRegisterServices: [],
    hidePrivacyTypes: [],
    isClearSid: true,
    wechatAppId: '',
    wxServiceName: '',
    wxUrl: '',
    popupServiceList: [],
    showPopup: false,
    googleClientId: '',
    googleRedirectUrl: '',
    clearCookiePathList: []
  },
  mutations: {
    changesValidSid (state, validSid) {
      state.validSid = validSid
    },
    changesUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    setServiceListUrl (state, serviceListUrl) {
      state.serviceListUrl = serviceListUrl
    },
    // 保存
    changeIsShowMyID (state, isShowMyID) {
      state.isShowMyID = isShowMyID
    },
    changeMyIdUrl (state, myIdUrl) {
      state.myIdUrl = myIdUrl
    },
    changeTagKeyList (state, tagKeyList) {
      state.tagKeyList = tagKeyList
    },
    saveTagCookie (state, saveTagCookie) {
      state.saveTagCookie = saveTagCookie
    },
    changeShowConfig (state, isShowConfig) {
      state.isShowConfig = isShowConfig
    },
    changeSocialLogin (state, socialLogin) {
      state.socialLogin = socialLogin
    },
    changeContactEmail (state, contactEmail) {
      state.contactEmail = contactEmail
    },
    changeSearchRegisterUrl (state, searchRegisterUrl) {
      state.searchRegisterUrl = searchRegisterUrl
    },
    getIsPc (state, isPc) {
      state.isPc = isPc
    },
    setIsSuccess (state, isSuccess) {
      state.isSuccess = isSuccess
    },
    setWebsocketUrl (state, websocketUrl) {
      state.websocketUrl = websocketUrl
    },
    setWechatAppId (state, wechatAppId) {
      state.wechatAppId = wechatAppId
    },
    setWxUrl (state, wxUrl) {
      state.wxUrl = wxUrl
    },
    setWxServiceName (state, wxServiceName) {
      state.wxServiceName = wxServiceName
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()]
})
export default store
