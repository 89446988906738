// install 是默认的方法。当外界在 use 这个组件的时候，就会调用本身的 install 方法，同时传一个 Vue 这个类的参数。
// export default {}.install = (Vue, options = {}) => {

export default {}.install = Vue => {
  // Vue.directive('repeatClick', {
  //     inserted(el, binding) {
  //         let timer
  //         el.addEventListener('click', e => {
  //             let delayTime = binding.value.delay || 1000
  //             clearTimeout(timer)
  //             timer = setTimeout(() => {
  //                 binding.value.params = binding.value.params || []
  //                 binding.value.event(...binding.value.params)
  //             }, delayTime)
  //         })
  //     }
  // })
  // 防抖
  let delayTime

  let timer

  let globalHandleEvent

  Vue.directive('repeatClick', {
    inserted (el, binding) {
      delayTime = binding.value.delay || 1000
      timer = null
      const handleEvent = e => {
        if (e && e.key && e.key != 'Enter' && e.key != 13) {
          return
        }
        if (timer !== null) {
          clearTimeout(timer)
        }
        const canSend = !timer // 第一次 先发送一次
        timer = setTimeout(() => {
          timer = null
        }, delayTime)
        if (canSend) {
          binding.value.params = binding.value.params || []
          binding.value.event(...binding.value.params)
        }
      }
      globalHandleEvent = handleEvent
      el.addEventListener('click', handleEvent)
    },
    unbind (el) {
      try {
        el.removeEventListener('click', globalHandleEvent)
      } catch (error) {
        window.console.info(error)
      }
    }
  })
  Vue.directive('repeatEnter', {
    inserted (el, binding) {
      delayTime = binding.value.delay || 1000
      timer = null
      const handleEvent = e => {
        if (e && e.key != 'Enter' && e.key != 13) {
          return
        }
        if (timer !== null) {
          clearTimeout(timer)
        }
        const canSend = !timer // 第一次 先发送一次

        timer = setTimeout(() => {
          timer = null
        }, delayTime)
        if (canSend) {
          binding.value.params = binding.value.params || []
          binding.value.event(...binding.value.params)
        }
      }

      globalHandleEvent = handleEvent
      el.addEventListener('keydown', handleEvent)
    },
    unbind (el) {
      try {
        el.removeEventListener('keydown', globalHandleEvent)
      } catch (error) {
        window.console.info(error)
      }
    }
  })
}
// 节流
// export default {}.install = (Vue, options = {}) => {
//     Vue.directive('repeatClick', {
//         inserted(el, binding) {
//             let delayTime = binding.value.delay || 2600
//             el.addEventListener('click', e => {
//                 if (!el.disabled) {
//                     el.disabled = true
//                     el.style.cursor = 'not-allowed'
//                     binding.value.params = binding.value.params || []
//                     binding.value.event(...binding.value.params)
//                     setTimeout(() => {
//                         el.style.cursor = 'pointer'
//                         el.disabled = false
//                     }, delayTime)
//                 }
//             })
//         }
//     })
